import React, { FC } from 'react'
import Image from '@sport1/news-styleguide/Image'
import Font from '@sport1/news-styleguide/Font'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Divider from '@sport1/news-styleguide/Divider'
import { AuthorInfoProps } from '@sport1/types/web'
import AuthorInfoSocial from './AuthorInfoSocial'
import { sport1Regular } from '@/helpers/fonts'

type Props = {
    component?: AuthorInfoProps
}

const AuthorInfo: FC<Props> = ({ component }) => {
    const { imageUrl, name, role, description, twitter, facebook, instagram, podcasts } =
        component?.authorInfo || {}
    if (
        !imageUrl ||
        !name ||
        !role ||
        !description ||
        (!twitter && !facebook && !instagram && !podcasts)
    ) {
        return null
    }

    return (
        <NonFlexingContainer
            backgroundColor="pearl"
            spaceX={['standard', 'standard', 'medium', 'big']}
            marginBottom="spacing-9"
            spaceY="medium"
        >
            <NonFlexingContainer
                centerHorizontal
                spaceY={['standard', 'standard', 'standard', 'medium']}
            >
                <NonFlexingContainer height={[60, 60, 100, 120]} width={[60, 60, 100, 120]}>
                    <Image
                        size="S"
                        alt="Author image"
                        imageUrl={imageUrl}
                        radiusType="circled"
                        height="100%"
                        width="100%"
                    />
                </NonFlexingContainer>

                <NonFlexingContainer
                    spaceTop={['small', 'small', 'standard', 'medium']}
                    centerHorizontal
                >
                    <Font
                        nextFontFamilyVariant={sport1Regular.style.fontFamily}
                        fontSize={['font-size-5', 'font-size-5', 'font-size-7', 'font-size-9']}
                        lineHeight={['18px', '18px', '22px', '28px']}
                    >
                        {name}
                    </Font>
                    <Font
                        nextFontFamilyVariant={sport1Regular.style.fontFamily}
                        fontSize={['font-size-3', 'font-size-3', 'font-size-4', 'font-size-5']}
                        lineHeight={['14px', '14px', '16px', '18px']}
                    >
                        {role}
                    </Font>
                </NonFlexingContainer>
            </NonFlexingContainer>
            <Divider dark testID="first-author-info-divider" />
            <AuthorInfoSocial
                socialAuthorInfo={{
                    twitter,
                    facebook,
                    instagram,
                    podcasts,
                }}
            />
            <>
                <Divider dark testID="second-author-info-divider" />
                <NonFlexingContainer spaceY={['standard', 'standard', 'medium']}>
                    <Font
                        nextFontFamilyVariant={sport1Regular.style.fontFamily}
                        fontSize={['font-size-3', 'font-size-3', 'font-size-4', 'font-size-5']}
                        lineHeight={['14px', '14px', '16px', '18px']}
                    >
                        {description}
                    </Font>
                </NonFlexingContainer>
            </>
        </NonFlexingContainer>
    )
}

export default AuthorInfo
