import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'

const IceHockeyStandingsHeader: FC<Pick<StandingsRowProps, 'competition'>> = ({ competition }) =>
    competition?.id && ['sr:tournament:225', 'sr:tournament:267'].includes(competition.id) ? ( // DEL, DEL2
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [24, 24, 48, 48],
                },
                {
                    value: 'S',
                    width: [24, 24, 48, 48],
                },
                {
                    value: 'OTS',
                    width: [30, 30, 48, 48],
                },
                {
                    value: 'PSS',
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'PSN',
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'OTN',
                    width: [24, 24, 48, 48],
                },
                {
                    value: 'N',
                    width: [30, 30, 48, 48],
                },
                {
                    value: '+/-',
                    width: [0, 0, 72, 72],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Diff.',
                    width: [0, 0, 56, 56],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Pkt.',
                    width: [30, 30, 48, 48],
                },
                {
                    value: 'PPS',
                    width: [30, 30, 48, 48],
                },
            ]}
        />
    ) : (
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [24, 24, 48, 48],
                },
                {
                    value: 'S',
                    width: [24, 24, 48, 48],
                },
                {
                    value: 'OTS',
                    width: [30, 30, 48, 48],
                },
                {
                    value: 'PSS',
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'PSN',
                    width: [0, 0, 48, 48],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'OTN',
                    width: [24, 24, 48, 48],
                },
                {
                    value: 'N',
                    width: [30, 30, 48, 48],
                },
                {
                    value: '+/-',
                    width: [0, 0, 72, 72],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Diff.',
                    width: [0, 0, 56, 56],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Pkt.',
                    width: [30, 30, 48, 48],
                },
            ]}
        />
    )

export default IceHockeyStandingsHeader
