import React, { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'

const HandballStandingsHeader: FC<Pick<StandingsRowProps, 'competition'>> = ({ competition }) =>
    competition?.id &&
    ['sr:tournament:149', 'sr:tournament:921', 'sr:tournament:245', 'sr:tournament:30'].includes(
        competition.id
    ) ? (
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'S-U-N',
                    width: [60, 60, 76, 76],
                },
                {
                    value: 'Heim',
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: 'Gast',
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex', 'flex'],
                },
                {
                    value: '+/-',
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex'],
                },
                {
                    value: 'Diff.',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Pkt.',
                    width: [34, 34, 72, 72],
                },
            ]}
        />
    ) : (
        <StandingsHeaderWithConfig
            columns={[
                {
                    value: 'Sp.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'S-U-N',
                    width: [60, 60, 76, 76],
                },
                {
                    value: '+/-',
                    width: [0, 0, 68, 76],
                    display: ['none', 'none', 'flex'],
                },
                {
                    value: 'Diff.',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Pkt.',
                    width: [34, 34, 72, 72],
                },
            ]}
        />
    )

export default HandballStandingsHeader
