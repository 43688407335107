import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'

const WmHeader: FC = () => (
    <StandingsHeaderWithConfig
        columns={[
            {
                value: 'Sp.',
                width: [30, 30, 48, 48],
            },
            {
                value: 'S-N',
                width: [40, 40, 56, 56],
            },
            {
                value: 'Pkt.',
                width: [34, 34, 56, 56],
            },
            {
                value: '+/-',
                width: [0, 0, 80, 80],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: 'Diff.',
                width: [34, 34, 56, 64],
            },
        ]}
    />
)

export default WmHeader
