import React, { FC, useMemo, useState } from 'react'
import { TeamStandingsClientModel } from '@sport1/types/sportData'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import DropDown from '@sport1/news-styleguide/DropDown'
import { DropdownSelectionElementModel } from '@sport1/types/web'
import { DropDownOption, ResponsiveType, SpacingsVariants } from '@sport1/news-styleguide/Types'
import StandingsList from '../Standings/List'
import StandingsQualifyInfos from '@/components/Standings/QualifyInfos'
import { useLivetickerMatch } from '@/utils/liveticker/LivetickerMatchProvider'
import { useNavigation } from '@/utils/navigation/Standard'
import ActionButtonsFooter from '@/components/ActionButtonsFooter'
import Headline from '@/components/Headline'
import { StandingWithPointsDeductionLabel } from '@/components/Standings/utils/standingsDataState/types'
import Fallback from '@/components/Fallback'
import { SizeTypes } from '@/types/size'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import useDropdownTracking from '@/hooks/useDropdownTracking'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { sport1Regular } from '@/helpers/fonts'

type CompetitionStandingsProps = {
    seasons?: DropdownSelectionElementModel[]
    initialTeamStandings?: TeamStandingsClientModel
    teamStandingsUrl?: string
    componentKey?: string
}

const CompetitionStandings: FC<CompetitionStandingsProps> = ({
    seasons,
    initialTeamStandings,
    teamStandingsUrl,
    componentKey,
}) => {
    const { match } = useLivetickerMatch()
    const { currentDevice } = useMediaQueryContext()
    const trackDropdownChange = useDropdownTracking()
    const { trackInteraction } = useTracking()
    const { navigation } = useNavigation()
    const headline = useMemo(
        () => (navigation?.tag?.title ? `${navigation?.tag?.title} Tabelle` : 'Tabelle'),
        [navigation?.tag?.title]
    )
    const testID = `${componentKey}-competition-standings`
    const dropdownLabel = 'Saison'
    const padding = ['spacing-7', 'spacing-9', 'spacing-9', 'spacing-10'] as ResponsiveType<
        keyof SpacingsVariants
    >
    const [selectedSeason, setSelectedSeason] = useState(seasons?.find(s => s.selected))
    const [teamStandings, setTeamStandings] = useState(initialTeamStandings)
    const teamStandingsLength = teamStandings?.standings?.length
    const competitionId = teamStandings?.competition?.id
    teamStandingsUrl = competitionId
        ? teamStandingsUrl?.replace('{competitionId}', competitionId)
        : undefined

    const onSeasonChange = React.useCallback(
        (option: DropDownOption<string>) => {
            const season = seasons?.find(s => s.id === option.value)
            if (season && teamStandingsUrl) {
                fetch(teamStandingsUrl.replace('{seasonId}', season.id)).then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            setSelectedSeason(season)
                            setTeamStandings(json)
                            trackInteraction({
                                interaction_category: 'widget',
                                interaction_action: 'tabelle_impression',
                                interaction_label: season.name,
                            })
                            trackDropdownChange()
                        })
                    }
                })
            }
        },
        [seasons, teamStandingsUrl, trackDropdownChange, trackInteraction]
    )

    return (
        <>
            <NonFlexingContainer
                backgroundColor="pearl"
                marginBottom={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-9']}
                testID={`${testID}-standings-with-dropdown`}
            >
                <NonFlexingContainer
                    testID={`${testID}-headline-dropdown-wrapper`}
                    paddingX={padding}
                    paddingTop={padding}
                >
                    <Headline
                        testID={`${testID}-headline`}
                        noStyle
                        component={{ text: headline }}
                    />
                    <NonFlexingContainer
                        testID={`${testID}-dropdown-label-wrapper`}
                        marginY="spacing-6"
                    >
                        <Font
                            nextFontFamilyVariant={sport1Regular.style.fontFamily}
                            testID={`${testID}-label`}
                            fontSize="font-size-4"
                            lineHeight="24px"
                            color="keshi-3"
                        >
                            {dropdownLabel}
                        </Font>
                        <NonFlexingContainer
                            testID={`${testID}-dropdown-wrapper`}
                            horizontal={currentDevice !== SizeTypes.MOBILE}
                            width={['100%', '30%', '30%']}
                        >
                            <FlexingContainer>
                                <DropDown
                                    testID={`${testID}-dropdown`}
                                    options={
                                        seasons?.map(s => ({ value: s.id, label: s.name })) ?? []
                                    }
                                    onOptionChange={onSeasonChange}
                                    selectedOption={{
                                        value: selectedSeason?.id,
                                        label: selectedSeason?.name,
                                    }}
                                />
                            </FlexingContainer>
                        </NonFlexingContainer>
                    </NonFlexingContainer>
                </NonFlexingContainer>

                <NonFlexingContainer
                    testID={`${testID}-standings-wrapper`}
                    paddingX={['spacing-none', 'spacing-9', 'spacing-9', 'spacing-10']}
                >
                    {teamStandingsLength ? (
                        <NonFlexingContainer
                            backgroundColor="pearl"
                            testID={`standings-${teamStandings?.competition?.name}`}
                            spaceBottom="standard"
                        >
                            {teamStandings?.standings?.map(
                                (standing: StandingWithPointsDeductionLabel, index) => (
                                    <StandingsList
                                        key={`standings-${competitionId}-${index}`}
                                        paddingBottom={
                                            index < teamStandingsLength - 1
                                                ? 'spacing-7'
                                                : 'spacing-none'
                                        }
                                        standing={standing}
                                        competition={teamStandings?.competition}
                                        contextIds={[
                                            match?.homeTeam?.id ?? '',
                                            match?.awayTeam?.id ?? '',
                                        ]}
                                    />
                                )
                            )}
                            <StandingsQualifyInfos qualifyInfos={teamStandings?.qualifyInfos} />
                        </NonFlexingContainer>
                    ) : (
                        <Fallback text="Keine Tabelle verfügbar." />
                    )}
                </NonFlexingContainer>
            </NonFlexingContainer>

            <ActionButtonsFooter
                navigationItems={navigation?.tag?.navigationItems?.filter(
                    e => e.value !== 'web-standings-sport'
                )}
                title={navigation?.tag?.title ?? ''}
            />
        </>
    )
}
export default CompetitionStandings
