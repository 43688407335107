import React, { FC, useMemo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import AuthorInfoContainer from '../AuthorInfoContainer'

export type SocialAuthorInfoType =
    | string
    | {
          name: string
          url?: string
          downloadUrl?: string
      }[]

type Props = {
    socialAuthorInfo: {
        twitter?: SocialAuthorInfoType
        facebook?: SocialAuthorInfoType
        instagram?: SocialAuthorInfoType
        podcasts?: SocialAuthorInfoType
    }
}

const AuthorInfoSocial: FC<Props> = ({ socialAuthorInfo }) => {
    const socialContent = useMemo(() => {
        const { twitter, facebook, instagram, podcasts } = socialAuthorInfo
        return (
            <>
                {twitter ? <AuthorInfoContainer mediaName="twitter" socialMedia={twitter} /> : null}
                {facebook ? (
                    <AuthorInfoContainer
                        mediaName="facebook"
                        socialMedia={facebook}
                        marginLeft={['spacing-none', 'spacing-none', 'spacing-7']}
                    />
                ) : null}
                {instagram ? (
                    <AuthorInfoContainer
                        mediaName="instagram"
                        socialMedia={instagram}
                        marginLeft={['spacing-none', 'spacing-none', 'spacing-7']}
                    />
                ) : null}
                {podcasts && podcasts.length > 0 ? (
                    <AuthorInfoContainer
                        mediaName="podcast"
                        socialMedia={podcasts}
                        marginLeft={['spacing-none', 'spacing-none', 'spacing-7']}
                    />
                ) : null}
            </>
        )
    }, [socialAuthorInfo])

    return (
        <>
            <NonFlexingContainer
                spaceY={['standard', 'standard', 'medium']}
                display={['flex', 'flex', 'none']}
            >
                {socialContent}
            </NonFlexingContainer>
            <NonFlexingContainer
                horizontal
                spaceY={['standard', 'standard', 'medium']}
                display={['none', 'none', 'flex']}
            >
                {socialContent}
            </NonFlexingContainer>
        </>
    )
}

export default AuthorInfoSocial
