import { FC, useMemo } from 'react'
import NbaHeader from './variants/NbaHeader'
import PercentageHeader from './variants/PercentageHeader'
import WmHeader from './variants/WmHeader'
import DefaultHeader from './variants/DefaultHeader'
import { StandingsRowProps } from '@/components/Standings/Row'

const BasketballStandingsHeader: FC<Pick<StandingsRowProps, 'competition'>> = ({ competition }) =>
    useMemo(() => {
        switch (competition?.id) {
            case 'sr:tournament:132':
                return <NbaHeader />
            case 'sr:tournament:227':
            case 'sr:tournament:156':
            case 'sr:tournament:264':
            case 'sr:tournament:138':
            case 'sr:tournament:141':
                return <PercentageHeader />
            case 'sr:tournament:441':
            case 'sr:tournament:285':
                return <WmHeader />
            default:
                return <DefaultHeader />
        }
    }, [competition?.id])

export default BasketballStandingsHeader
