import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'

const FieldHockeyStandingsHeader: FC = () => (
    <StandingsHeaderWithConfig
        columns={[
            {
                value: 'Sp.',
                width: [34, 34, 56, 64],
            },
            {
                value: 'S-U-N',
                width: [48, 48, 56, 64],
            },
            {
                value: '+/-',
                width: [40, 40, 56, 64],
            },
            {
                value: 'Diff.',
                width: [34, 34, 56, 64],
            },
            {
                value: 'Pkt.',
                width: [34, 34, 56, 64],
            },
        ]}
    />
)

export default FieldHockeyStandingsHeader
