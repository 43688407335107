import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ResponsiveType } from '@sport1/news-styleguide/Types'

type PlaceHolderBoxProps = {
    height: ResponsiveType<number>
}

const PlaceHolderBox: VFC<PlaceHolderBoxProps> = ({ height }) => (
    <NonFlexingContainer
        testID="placeholder"
        position="relative"
        width="100%"
        marginBottom="spacing-9"
    >
        <NonFlexingContainer testID="height" backgroundColor="pearl" height={height} />
    </NonFlexingContainer>
)

export default PlaceHolderBox
