import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'

const BaseballStandingsHeader: FC = () => (
    <StandingsHeaderWithConfig
        columns={[
            {
                value: 'Sp.',
                width: [34, 34, 56, 64],
            },
            {
                value: 'S-N',
                width: [48, 48, 56, 64],
            },
            {
                value: '%',
                width: [48, 48, 56, 64],
            },
            {
                value: 'GB',
                width: [34, 34, 56, 56],
            },
            {
                value: 'Heim',
                width: [0, 0, 70, 70],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: 'Gast',
                width: [0, 0, 70, 70],
                display: ['none', 'none', 'flex', 'flex'],
            },
        ]}
    />
)

export default BaseballStandingsHeader
