import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import Button from '@sport1/news-styleguide/Button'
import { ResponsiveType } from '@sport1/news-styleguide/Types'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { ActionButtonsProps } from '@/components/ActionButtons'

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 */

type ActionButtonsRowProps = Pick<
    ActionButtonsProps,
    'actionButtons' | 'backgroundColor' | 'onPressCallback'
> & {
    numOfColumns?: number
    testIdPrefix?: string
    spaceBetween?: ResponsiveType<number>
    buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'highlight'
}

const ActionButtonsRow: VFC<ActionButtonsRowProps> = ({
    actionButtons,
    buttonVariant = 'tertiary',
    backgroundColor,
    onPressCallback,
    numOfColumns = 2,
    testIdPrefix = '',
    spaceBetween = 12,
}) => {
    if (!actionButtons.length) {
        return null
    }
    const actionButtonRows = new Array(Math.ceil(actionButtons.length / numOfColumns))
        .fill(0)
        .map((_, index) =>
            actionButtons.slice(index * numOfColumns, index * numOfColumns + numOfColumns)
        )
    return (
        <>
            {actionButtonRows.map((buttons, index) => (
                <NonFlexingContainer
                    key={`action-button-row-${buttons[0]?.title}`}
                    marginTop={index !== 0 ? 'spacing-6' : 'spacing-none'}
                    horizontal
                    centerHorizontal
                    backgroundColor={backgroundColor}
                >
                    {buttons.map((button, index) => {
                        return (
                            <React.Fragment key={`${index}-${button.title.slice(0, 5)}`}>
                                {index > 0 ? (
                                    <NonFlexingContainer
                                        backgroundColor={backgroundColor}
                                        width={spaceBetween}
                                    />
                                ) : null}
                                <FlexingContainer
                                    centerVertical
                                    maxWidth={
                                        actionButtons.length === 1
                                            ? ['100%', '343px', '343px']
                                            : undefined
                                    }
                                    width="100%"
                                    testID="action-button-container"
                                >
                                    <Button
                                        expand
                                        testID={`${testIdPrefix}action-button-${button.title.toLowerCase()}`}
                                        buttonType="largeButton"
                                        textAlign="center"
                                        buttonVariant={buttonVariant}
                                        text={button.title}
                                        href={button.href}
                                        webRel={nofollowExternal}
                                        onPress={onPressCallback}
                                    />
                                </FlexingContainer>
                            </React.Fragment>
                        )
                    })}
                </NonFlexingContainer>
            ))}
        </>
    )
}

export default ActionButtonsRow
