import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import ActionButtonsRow from '@/components/ActionButtons/Row'
import { ActionButtonsProps } from '@/components/ActionButtons'

const NonMobileActionButtons: VFC<ActionButtonsProps> = ({
    actionButtons,
    backgroundColor,
    onPressCallback,
    marginBottom,
    marginTop,
    buttonVariant,
    testID,
    ...rest
}) => {
    const testIdPrefix = 'non-mobile-'
    return (
        <NonFlexingContainer
            testID={`${testIdPrefix}${testID}`}
            marginTop={marginTop}
            marginBottom={marginBottom}
            backgroundColor={backgroundColor}
            display={['none', 'none', 'flex']}
        >
            <ActionButtonsRow
                actionButtons={actionButtons}
                buttonVariant={buttonVariant}
                backgroundColor={backgroundColor}
                onPressCallback={onPressCallback}
                numOfColumns={3}
                testIdPrefix={testIdPrefix}
                {...rest}
            />
        </NonFlexingContainer>
    )
}

export default NonMobileActionButtons
