import { BundesligaHubProps, BundesligaHubTeamProps, TrackingDataProps } from '@sport1/types/web'
import { BreakPointProps, getValueByBreakpoint } from '@/helpers/breakpoint'

export const getVariant = (
    hub: BundesligaHubProps
): { key: 'variant'; value: string } | undefined => {
    const selectedTab = hub.tabs.find(tab => tab.selected)
    if (selectedTab) {
        return { key: 'variant', value: selectedTab.name }
    }
}

export const getCreation = (
    hub: BundesligaHubProps,
    theme?: BreakPointProps
): { key: 'creation'; value: string } | undefined => {
    const creation = hub.meta?.tracking?.find(item => item.key === 'creation')
    if (creation) {
        const [componentType] = creation.value.split(' |')
        if (componentType && theme) {
            const presentation = getValueByBreakpoint(
                ['slider', 'slider', 'dashboard', 'dashboard', 'dashboard'],
                theme
            )
            return { key: 'creation', value: `${componentType} | ${presentation}` }
        }
    }
}

export const getAdditionalTrackingData = (
    hub: BundesligaHubProps,
    theme?: BreakPointProps
): TrackingDataProps[] => {
    const additionalTrackingData: TrackingDataProps[] = []
    const variant = getVariant(hub)
    const creation = getCreation(hub, theme)
    if (variant) {
        additionalTrackingData.push(variant)
    }
    if (creation) {
        additionalTrackingData.push(creation)
    }
    return additionalTrackingData
}

export const insertSelectedFilter = (
    hub: BundesligaHubProps,
    team?: BundesligaHubTeamProps
): BundesligaHubProps => {
    const nextFormat: { key: 'format'; value: string } = {
        key: 'format',
        value: team ? team.name : 'all',
    }
    hub.content?.forEach(item => {
        if (item.meta?.tracking) {
            const formatIndex = item.meta.tracking?.findIndex(value => value.key === 'format')
            if (formatIndex !== -1) {
                item.meta.tracking[formatIndex] = nextFormat
            } else {
                item.meta.tracking.push(nextFormat)
            }
        }
    })
    return hub
}
