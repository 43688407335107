import React, { VFC } from 'react'
import { CompetitionClientModel, TeamStandingClientModel } from '@sport1/types/sportData'
import SoccerStandingsHeader from './soccer'
import DartsStandingsHeader from './darts'
import HandballStandingsHeader from '@/components/Standings/Header/handball'
import FieldHockeyStandingsHeader from '@/components/Standings/Header/fieldhockey'
import VolleyballStandingsHeader from '@/components/Standings/Header/volleyball'
import BaseballStandingsHeader from '@/components/Standings/Header/baseball'
import BasketballStandingsHeader from '@/components/Standings/Header/basketball'
import AmericanFootballStandingsHeader from '@/components/Standings/Header/americanFootball'
import IceHockeyStandingsHeader from '@/components/Standings/Header/icehockey'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

type Props = {
    sportIdentifier?: string
    competition?: CompetitionClientModel
    team?: TeamStandingClientModel
    layoutMode?: 'RIGHT_COLUMN' | 'RESPONSIVE'
}

const StandingsHeader: VFC<Props> = ({
    sportIdentifier,
    layoutMode = 'RESPONSIVE',
    competition,
}) => {
    switch (sportIdentifier) {
        case sportIdentifiers.soccer.name:
            return <SoccerStandingsHeader layoutMode={layoutMode} />
        case sportIdentifiers.darts.name:
            return <DartsStandingsHeader competition={competition} />
        case sportIdentifiers.handball.name:
            return <HandballStandingsHeader competition={competition} />
        case sportIdentifiers.field_hockey.name:
            return <FieldHockeyStandingsHeader />
        case sportIdentifiers.indoor_volleyball.name:
            return <VolleyballStandingsHeader competition={competition} />
        case sportIdentifiers.basketball.name:
            return <BasketballStandingsHeader competition={competition} />
        case sportIdentifiers.baseball.name:
            return <BaseballStandingsHeader />
        case sportIdentifiers.american_football.name:
            return <AmericanFootballStandingsHeader competition={competition} />
        case sportIdentifiers.icehockey.name:
            return <IceHockeyStandingsHeader competition={competition} />
    }
    return null
}

export default StandingsHeader
