import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ActionButtonsProps } from '@/components/ActionButtons'
import ActionButtonsRow from '@/components/ActionButtons/Row'

const MobileActionButtons: VFC<ActionButtonsProps> = ({
    actionButtons,
    backgroundColor,
    onPressCallback,
    marginTop,
    marginBottom,
    testID,
    buttonVariant,
    ...rest
}) => {
    const testIdPrefix = 'mobile-'
    return (
        <NonFlexingContainer
            testID={`${testIdPrefix}${testID}`}
            marginTop={marginTop}
            marginBottom={marginBottom}
            backgroundColor={backgroundColor}
            display={['flex', 'flex', 'none']}
        >
            <ActionButtonsRow
                actionButtons={actionButtons}
                buttonVariant={buttonVariant}
                backgroundColor={backgroundColor}
                onPressCallback={onPressCallback}
                numOfColumns={2}
                testIdPrefix={testIdPrefix}
                {...rest}
            />
        </NonFlexingContainer>
    )
}

export default MobileActionButtons
