import React, { useMemo, VFC } from 'react'
import { ActionButtonProps, LabelProps, TagProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import ActionButtons from '@/components/ActionButtons'
import { GLOBAL_LAYOUT_COMPONENT_SPACE } from '@/utils/content/constants'

const ACTIVE_ACTION_BUTTON_VALUES = [
    'web-gameplan-sport',
    'web-standings-sport',
    'web-conference-ticker',
]

const ActionButtonsFooter: VFC<Pick<TagProps | LabelProps, 'title' | 'navigationItems'>> = ({
    title,
    navigationItems,
}) => {
    const actionButtons = useMemo(() => {
        const actionButtons: ActionButtonProps[] = []
        navigationItems?.forEach(item => {
            if (ACTIVE_ACTION_BUTTON_VALUES.includes(item.value)) {
                actionButtons.push({ title: item.title, href: item.href || '' })
            }
        })
        return actionButtons
    }, [navigationItems])

    if (!actionButtons.length) {
        return null
    }

    return (
        <>
            <NonFlexingContainer
                backgroundColor="pearl"
                paddingX={GLOBAL_LAYOUT_COMPONENT_SPACE}
                paddingTop={GLOBAL_LAYOUT_COMPONENT_SPACE}
                paddingBottom={['spacing-6', 'spacing-7', 'spacing-9']}
                testID="action-button-footer"
            >
                <NonFlexingContainer paddingBottom="spacing-7">
                    <CardHeadline
                        text={`Alles zu ${title}`}
                        cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineM']}
                    />
                </NonFlexingContainer>

                <ActionButtons actionButtons={actionButtons} />
            </NonFlexingContainer>
        </>
    )
}

export default ActionButtonsFooter
