import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'
import { StandingsRowProps } from '@/components/Standings/Row'
import getDartsStandingsFormat from '@/components/Standings/utils/getDartsStandingsFormat'

const DartsStandingsHeader: FC<Pick<StandingsRowProps, 'competition'>> = ({ competition }) =>
    getDartsStandingsFormat(competition) === 'GRAND_SLAMS' ? (
        <StandingsHeaderWithConfig
            competitorLabel="Spieler"
            columns={[
                {
                    value: 'Sp.',
                    width: [28, 28, 56, 64],
                },
                {
                    value: 'S-N',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'Legs',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'Diff.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'Pkt.',
                    width: [28, 28, 56, 64],
                },
            ]}
        />
    ) : (
        <StandingsHeaderWithConfig
            competitorLabel="Spieler"
            columns={[
                {
                    value: 'Eventsiege',
                    width: [48, 48, 74, 74],
                },
                {
                    value: 'Legs',
                    width: [40, 40, 56, 64],
                },
                {
                    value: 'Diff.',
                    width: [34, 34, 56, 64],
                },
                {
                    value: 'Pkt.',
                    width: [34, 34, 56, 64],
                },
            ]}
        />
    )

export default DartsStandingsHeader
