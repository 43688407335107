import { FC } from 'react'
import StandingsHeaderWithConfig from '@sport1/news-styleguide/StandingsHeaderWithConfig'

const PercentageHeader: FC = () => (
    <StandingsHeaderWithConfig
        columns={[
            {
                value: 'Sp.',
                width: [30, 30, 48, 48],
            },
            {
                value: 'S-N',
                width: [40, 40, 56, 64],
            },
            {
                value: '%',
                width: [34, 34, 56, 64],
            },
            {
                value: 'Heim',
                width: [0, 0, 64, 64],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: 'Gast',
                width: [0, 0, 56, 64],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: '+/-',
                width: [0, 0, 80, 80],
                display: ['none', 'none', 'flex', 'flex'],
            },
            {
                value: 'Diff.',
                width: [40, 40, 56, 64],
            },
            {
                value: 'Serie',
                width: [34, 34, 56, 64],
            },
        ]}
    />
)

export default PercentageHeader
