import { FC, memo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { sport1Regular } from '@/helpers/fonts'

const StandingsError: FC = () => {
    return (
        <NonFlexingContainer width="100%" backgroundColor="pearl">
            <Font
                fontVariant="Body-L"
                fontFamilyVariant="Sport1-Regular"
                nextFontFamilyVariant={sport1Regular.style.fontFamily}
            >
                Fehler beim laden der Daten.
            </Font>
        </NonFlexingContainer>
    )
}

export default memo(StandingsError)
