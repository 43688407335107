import React, { FC, useCallback } from 'react'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import Touchable from '@sport1/news-styleguide/Touchable'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import { SocialAuthorInfoType } from '../AuthorInfoSocial'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { sport1Regular } from '@/helpers/fonts'

type MediaName = 'facebook' | 'instagram' | 'twitter' | 'podcast'

const socialUrl: {
    [key in string]: string
} = {
    facebook: 'https://www.facebook.com',
    instagram: 'https://www.instagram.com',
    twitter: 'https://www.twitter.com',
}

interface Props extends SystemSpacingsProps {
    socialMedia: SocialAuthorInfoType
    mediaName: MediaName
}

const getSocialHref = (mediaName: MediaName, socialMedia: string) => {
    const pattern = new RegExp('^(https?)://')

    if (!pattern.test(socialMedia)) {
        return `${socialUrl[mediaName]}/${socialMedia}`
    }
    return socialMedia
}

const AuthorInfoContainer: FC<Props> = ({ socialMedia, mediaName, marginLeft }) => {
    const { trackInteraction } = useTracking()

    const xConvertedMediaName = mediaName === 'twitter' ? 'x-(twitter)' : mediaName

    const trackOnSocialPress = useCallback(
        (mediaName: string) => {
            trackInteraction({
                interaction_category: 'social',
                interaction_action: 'redirect',
                interaction_label: mediaName,
            })
        },
        [trackInteraction]
    )

    if (Array.isArray(socialMedia)) {
        return (
            <NonFlexingContainer horizontal centerVertical marginLeft={marginLeft}>
                <FontelloIcon name={xConvertedMediaName} color="onyx" fontSize="FS18" />
                <NonFlexingContainer paddingX="spacing-2">
                    {socialMedia.map((mediaElement, index) => (
                        <Touchable
                            key={`${mediaElement.name}`}
                            testID={`podcast-${mediaElement.name}`}
                            href={mediaElement.url}
                            onPress={() => trackOnSocialPress(mediaName)}
                        >
                            <Font
                                nextFontFamilyVariant={sport1Regular.style.fontFamily}
                                fontSize={[
                                    'font-size-2',
                                    'font-size-2',
                                    'font-size-3',
                                    'font-size-4',
                                ]}
                                lineHeight={['12px', '12px', '14px', '16px']}
                            >
                                {`${mediaElement.name}${
                                    socialMedia.length - 1 !== index ? ',' : ''
                                } `}
                            </Font>
                        </Touchable>
                    ))}
                </NonFlexingContainer>
            </NonFlexingContainer>
        )
    }
    return (
        <NonFlexingContainer horizontal centerVertical marginLeft={marginLeft}>
            <FontelloIcon name={xConvertedMediaName} color="onyx" fontSize="FS18" />
            <NonFlexingContainer paddingX="spacing-2">
                <Touchable
                    href={getSocialHref(mediaName, socialMedia)}
                    onPress={() => trackOnSocialPress(mediaName)}
                >
                    <Font
                        nextFontFamilyVariant={sport1Regular.style.fontFamily}
                        fontSize={['font-size-2', 'font-size-2', 'font-size-3', 'font-size-4']}
                        lineHeight={['12px', '12px', '14px', '16px']}
                    >
                        {socialMedia}
                    </Font>
                </Touchable>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default AuthorInfoContainer
